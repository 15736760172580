import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () =>
        import ('../views/index.vue')
}, {
    path: '/index',
    name: 'index',
    component: () =>
        import ('../views/index.vue')
}, {
    path: '/workbigscreen',
    name: 'workbigscreen',
    component: () =>
        import ('../views/workbigscreen.vue')
}, {
    path: '/mainbigscreen',
    name: 'mainbigscreen',
    component: () =>
        import ('../views/mainbigscreen.vue')
}, {
    path: '/selectworkgroup',
    name: 'selectworkgroup',
    component: () =>
        import ('../views/selectworkgroup.vue')
}, {
    path: '/groupbigscreen',
    name: 'groupbigscreen',
    component: () =>
        import ('../views/groupbigscreen.vue')
}, {
    path: '/workshop',
    name: 'workshop',
    component: () =>
        import ('../views/workshop.vue')
}, {
    path: '/workcut',
    name: 'workcut',
    component: () =>
        import ('../views/workcut.vue')
}, {
    path: '/workorder',
    name: 'workorder',
    component: () =>
        import ('../views/workorder.vue')
}, {
    path: '/workyeid',
    name: 'workyeid',
    component: () =>
        import ('../views/workyeid.vue')
}, {
    path: '/worklookyield',
    name: 'worklookyield',
    component: () =>
        import ('../views/worklookyield.vue')
}, {
    path: '/worklookyieldbig',
    name: 'worklookyieldbig',
    component: () =>
        import ('../views/worklookyieldbig.vue')
}, {
    path: '/workshow',
    name: 'workshow',
    component: () =>
        import ('../views/workshow.vue')
}, {
    path: '/inventoryshow',
    name: 'inventoryshow',
    component: () =>
        import ('../views/inventoryshow.vue')
}, {
    path: '/ctinventoryshow',
    name: 'ctinventoryshow',
    component: () =>
        import ('../views/ctinventoryshow.vue')
}, {
    path: '/qascreen',
    name: 'qascreen',
    component: () =>
        import ('../views/qascreen/index.vue')
}, {
    path: '/qaindex',
    name: 'qaindex',
    component: () =>
        import ('../views/qaindex.vue')
}, {
    path: '/qayiedindex',
    name: 'qayiedindex',
    component: () =>
        import ('../views/qayiedindex.vue')
}, {
    path: '/wxordershow',
    name: 'wxordershow',
    component: () =>
        import ('../views/wxordershow.vue')
}, {
    path: '/ctwareshow',
    name: 'ctwareshow',
    component: () =>
        import ('../views/ctwareshow.vue')
}, {
    path: '/warecutshow',
    name: 'warecutshow',
    component: () =>
        import ('../views/warecutshow.vue')
}, {
    path: '/workmainbigscreen',
    name: 'workmainbigscreen',
    component: () =>
        import ('../views/workmainbigscreen.vue')
}, {
    path: '/ordermainbig',
    name: 'ordermainbig',
    component: () =>
        import ('../views/ordermainbig.vue')
}, {
    path: '/orderproductionbig',
    name: 'orderproductionbig',
    component: () =>
        import ('../views/orderproductionbig.vue')
}, {
    path: '/warehsshow',
    name: 'warehsshow',
    component: () =>
        import ('../views/warehsshow.vue')
}, {
    path: '/traditionworkbigshow',
    name: 'traditionworkbigshow',
    component: () =>
        import ('../views/traditionworkbigshow.vue')
}, {
    path: '/traditionproductionbigshow',
    name: 'traditionproductionbigshow',
    component: () =>
        import ('../views/traditionproductionbigshow.vue')
}, {
    path: '/traditioncutbigshow',
    name: 'traditioncutbigshow',
    component: () =>
        import ('../views/traditioncutbigshow.vue')
}, {
    path: '/traditionwarebigshow',
    name: 'traditionwarebigshow',
    component: () =>
        import ('../views/traditionwarebigshow.vue')
}, {
    path: '/traditionqabigshow',
    name: 'traditionqabigshow',
    component: () =>
        import ('../views/traditionqabigshow.vue')
}, {
    path: '/lymainbigscreen',
    name: 'lymainbigscreen',
    component: () =>
        import ('../views/lymainbigscreen.vue')
}]
const router = new VueRouter({
    mode: "history",
    routes
})

export default router