// import { getWorkShopDataById} from '../libs/axios';
let util = {

};
// 添加所有顶部导航栏下的菜单路由
util.initData = function(vm) {
    var params = window.location.href.split('?');
    var dz_url = params[1].split('&');
    if (dz_url.length == 1) {
        vm.$store.commit('setDeptId', decodeURIComponent(dz_url[0].split("=")[1]));
        vm.$store.commit('setOrderId', dz_url[1].split("=")[1]);
    }
    if (dz_url.length == 2) {
        vm.$store.commit('setDeptId', decodeURIComponent(dz_url[0].split("=")[1]));
        vm.$store.commit('setWorkShopName', decodeURIComponent(dz_url[1].split("=")[1]));
        vm.$store.commit('setOrderId', dz_url[2].split("=")[1]);
    }
    if (dz_url.length == 3) {
        vm.$store.commit('setDeptId', decodeURIComponent(dz_url[0].split("=")[1]));
        vm.$store.commit('setWorkShopName', decodeURIComponent(dz_url[1].split("=")[1]));
        vm.$store.commit('setSeqGuids', dz_url[2].split("=")[1]);
        vm.$store.commit('setOrderId', dz_url[3].split("=")[1]);
    }
    if (dz_url.length == 4) {
        vm.$store.commit('setDeptId', decodeURIComponent(dz_url[0].split("=")[1]));
        vm.$store.commit('setWorkShopName', decodeURIComponent(dz_url[1].split("=")[1]));
        vm.$store.commit('setSeqGuids', dz_url[2].split("=")[1]);
        vm.$store.commit('setSeqNames', decodeURIComponent(dz_url[3].split("=")[1]));
        vm.$store.commit('setOrderId', dz_url[4].split("=")[1]);
    }
    if (dz_url.length == 5) {
        vm.$store.commit('setDeptId', decodeURIComponent(dz_url[0].split("=")[1]));
        vm.$store.commit('setWorkShopName', decodeURIComponent(dz_url[1].split("=")[1]));
        vm.$store.commit('setSeqGuids', dz_url[2].split("=")[1]);
        vm.$store.commit('setSeqNames', decodeURIComponent(dz_url[3].split("=")[1]));
        vm.$store.commit('setWorkId', decodeURIComponent(dz_url[4].split("=")[1]));
        vm.$store.commit('setOrderId', dz_url[5].split("=")[1]);
    }
    if (dz_url.length == 6) {
        vm.$store.commit('setDeptId', decodeURIComponent(dz_url[0].split("=")[1]));
        vm.$store.commit('setWorkShopName', decodeURIComponent(dz_url[1].split("=")[1]));
        vm.$store.commit('setSeqGuids', dz_url[2].split("=")[1]);
        vm.$store.commit('setSeqNames', decodeURIComponent(dz_url[3].split("=")[1]));
        vm.$store.commit('setWorkId', decodeURIComponent(dz_url[4].split("=")[1]));
        vm.$store.commit('setSeqWageGuids', decodeURIComponent(dz_url[5].split("=")[1]));
        vm.$store.commit('setOrderId', dz_url[6].split("=")[1]);
    }
    if (dz_url.length == 7) {
        vm.$store.commit('setDeptId', decodeURIComponent(dz_url[0].split("=")[1]));
        vm.$store.commit('setWorkShopName', decodeURIComponent(dz_url[1].split("=")[1]));
        vm.$store.commit('setSeqGuids', dz_url[2].split("=")[1]);
        vm.$store.commit('setSeqNames', decodeURIComponent(dz_url[3].split("=")[1]));
        vm.$store.commit('setWorkId', decodeURIComponent(dz_url[4].split("=")[1]));
        vm.$store.commit('setSeqWageGuids', decodeURIComponent(dz_url[5].split("=")[1]));
        vm.$store.commit('setOrderId', dz_url[6].split("=")[1]);
    }

}



export default util;