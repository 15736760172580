import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import util from '@/libs/util'
import VideoPlayer from 'vue-video-player'
import Video from 'video.js'
import scroll from 'vue-seamless-scroll'

import 'font-awesome/css/font-awesome.min.css'

import 'video.js/dist/video-js.css'
import './assets/scss/icon/iconfont.css'
Vue.prototype.$video = Video
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(dataV);
Vue.use(scroll)
Vue.use(VideoPlayer)

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';

// 引入全局css
import './assets/scss/style.scss';


//引入echart
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    mounted() {
        this.id = this.$route.id;
        // 初始化菜单
        util.initData(this);
    },
    data: function() {
        return {
            id: "",
            workShopName: "",
            seqGuids: "",
            seqNames: "",
        }
    },

    render: (h) => h(App),
}).$mount('#app');