import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const state = {
    WorkInfo: {},
    id: "",
    workShopName: "",
    seqGuids: "",
    seqWageGuids: "",
    seqNames: "",
    divshow: "0",
    workid: "",
    deptId: "",
    orderId: "",
    groupId:"",
}
const mutations = {
    updateWorkInfo(state, infos) {
        state.WorkInfo = infos
    },
    setId(state, id) {
        state.id = id
    },
    setGroupId(state, groupId) {
        state.groupId = groupId
    },
    setWorkId(state, workid) {
        state.workid = workid
    },
    setOrderId(state, orderId) {
        state.orderId = orderId
    },
    setDivshow(state, divshow) {
        state.divshow = divshow
    },
    setWorkShopName(state, workShopName) {
        state.workShopName = workShopName
    },
    setSeqGuids(state, seqGuids) {
        state.seqGuids = seqGuids
    },
    setSeqWageGuids(state, seqWageGuids) {
        state.seqWageGuids = seqWageGuids
    },
    setDeptId(state, deptId) {
        state.deptId = deptId
    },
    setSeqNames(state, seqNames) {
        state.seqNames = seqNames
    },
}
export default new Vuex.Store({
    state,
    mutations,
    actions: {},
    modules: {}
})